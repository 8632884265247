import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  ngOnInit(): void {
  this.myFunction();
  this.myFunction1();
  }
  myFunction(){
    var dots = <HTMLDivElement>document.getElementById("dots");
    var moreText = <HTMLDivElement>document.getElementById("more");
    var btnText = <HTMLButtonElement>document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }
  myFunction1(){
    var dots1 = <HTMLDivElement>document.getElementById("dots1");
    var moreText1 = <HTMLDivElement>document.getElementById("more1");
    var btnText1 = <HTMLButtonElement>document.getElementById("myBtn1");

    if (dots1.style.display === "none") {
      dots1.style.display = "inline";
      btnText1.innerHTML = "Read more";
      moreText1.style.display = "none";
    } else {
      dots1.style.display = "none";
      btnText1.innerHTML = "Read less";
      moreText1.style.display = "inline";
    }
  }
  title = 'punjabsindh';

  public configForTest: SwiperOptions = {
    autoplay: true,

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      // when window width is >= 640px
      767: {
        slidesPerView: 1,
        spaceBetween: 30
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    },
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-nexts',
      prevEl: '.swiper-button-prevs',
    },
  };

  public configForTest1: SwiperOptions = {
    autoplay: true,
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-nexts',
      prevEl: '.swiper-button-prevs',
    },
  };


}


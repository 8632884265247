<!-- NAVBAR -->
<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light w-100">

  <img class="logo d-none d-md-none-lg-block" src="/assets/PUNJAB SIND/logo/thumb-PS-Logo-Cropped.png" alt="">
  <div class="d-block d-lg-none">
    <div class="row">
      <div class="col-6 d-flex justify-content-start pnone">
        <img class="logo2 " src="/assets/PUNJAB SIND/logo/thumb-PS-Logo-Cropped.png" alt="">
      </div>
      <div class="col-6 d-flex justify-content-end pnone">
        <button class="navbar-toggler me-2 " type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4 col-md-4 pnone">
        <a class="cor d-block d-md-block d-lg-none " href="http://shop.punjabsind.com/" target=_blank>
          <div class="button3">
            <p class="btnText mt-2">
              Shop Now
            </p>
          </div>
        </a>
      </div>
      <div class="col-2 col-md-4 pnone"></div>
      <div class="col-6  col-md-4 d-flex justify-content-end pnone">
        <a class="cor d-block d-md-block d-lg-none " href="https://wa.me/+919619888880" target="_blank">
          <div class="button2">
            <p class="btnText mt-2">
              WhatsApp Now
            </p>
          </div>
        </a>
      </div>
    </div>

  </div>
  <br>
  <!-- <button class="navbar-toggler me-2 d-none d-md-block" type="button" data-toggle="collapse" data-target="#navbarNav"
    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->

  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#our-story">Our Story</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#contact-us">Contact Us</a>
      </li>
      <a class="cor d-none d-lg-block " href="http://shop.punjabsind.com/" target=_blank>
        <div class="button">
          <p class="btnText mt-2">
            Shop Now
          </p>
          <div class="btnTwo">
            <p class="btnText2 mt-2">GO!</p><a class="nav-link "></a>
          </div>
        </div>
      </a>

      <a class="cor d-none d-lg-block ms-4" href="https://wa.me/+919619888880" target="_blank">
        <div class="button1">
          <p class="btnText mt-2">
            WhatsApp Now
          </p>
        </div>
      </a>

      <!-- <a class="ps-2" >
        <div >
          <p class="txt-white btn mt-2 btnText"  style="background-color: #25D366;">
            WhatsApp Now
          </p>
        </div>
      </a> -->

      <!-- <a class="cor " href="https://api.whatsapp.com/send?phone=+91 96198 88880 &text=." target="_blank" >
        <div class="button">
          <p class="txt-white mt-2" style="background-color: #25D366;">
            WhatsApp Now
          </p>
        </div>
      </a> -->

      <!-- <button style="background-color:#25D366;" class="abtn ms-4"><a class="txt-white" href="https://api.whatsapp.com/send?phone=+91 96198 88880 &text=." target="_blank" style="text-decoration: none;color: white;" >WhatsApp Now</a></button> -->

    </ul>
  </div>
</nav>


<!-- BANNER-1 -->
<!-- <div id="whatsapplogo  w-100" style="background-color: white;" class="pt-5 d-none d-lg-block">
  <div class="container">
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"></div>
      <div class="col-4">
        <div class="card1">
          <div class="card-body">
            <h4 class="fontt" style="color: #FFFB69;">Connect with us effortlessly on <span style="color:#25D366;font-weight: bold; ">WhatsApp</span></h4>
            <h5 class="fontp " style="color: #ffffff;"><a
                style="color:white; text-decoration: none; cursor: pointer; font-weight: bold;"
                href="tel:+91 96198 88880">96198 88880 </a></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="whatsapplogo  w-100" style="background-color: white;" class="pt-5 d-block d-lg-none">
  <div class="container">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-6">
        <div class="card1">
          <div class="card-body">
            <h4 class="fontt" style="color: #FFFB69;">Connect with us effortlessly on <span style="color:#25D366;font-weight: bold; ">WhatsApp</span></h4>
            <h5 class="fontp " style="color: #ffffff;"><a
                style="color:white; text-decoration: none; cursor: pointer; font-weight: bold;"
                href="tel:+91 96198 88880">96198 88880 </a></h5>
          </div>
        </div>
      </div>
       <div class="col-3"></div>
    </div>
  </div>
</div> -->

<!-- <div class="container">
  <h4 class="fontt" style="color: #FFFB69;">Connect with us effortlessly on <span style="color:#25D366;font-weight: bold; ">WhatsApp</span></h4>
  <h5 class="fontp " style="color: #ffffff;"><a
      style="color:black; text-decoration: none; cursor: pointer; font-weight: bold;"
      href="tel:+91 96198 88880">96198 88880 </a></h5>
</div> -->

<section id="banner-1">

  <div class="img-container">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100 banner-1" style="border-radius: 25px !important;"
            src="/assets/PUNJAB SIND/BANNER 1/Punjab Sindh Banners Final-01.png" alt="First slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100 banner-1" style="border-radius: 25px !important;"
            src="/assets/STANDY HORIZONTAL.jpg" alt="Second slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100 banner-1" style="border-radius: 25px !important;" src="/assets/cakebanner.jpg"
            alt="Third slide">
        </div>

      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only"></span>
      </a>
    </div>
    <!-- <img class="banner-1" style="border-radius: 25px !important;"
      src="/assets/PUNJAB SIND/BANNER 1/Punjab Sindh Banners Final-01.png" alt=""> -->
  </div>
</section>

<!-- OUR STORY -->

<section id="our-story">
  <div class="our-story-container">
    <div class="row">
      <div class="col-lg-8 col-sm-12">
        <h1 class="title">Our Story</h1>
        <div class="write-up-our-story d-none d-lg-block">
          <p>Punjab Sind was established more than 5 decades ago in the year 1969 by late Shri. Jagjit Singh Bajaj who
            inculcated the values of “uncompromising quality” and “taste” at all times, his son the current Chairman &
            Managing Director, Mr. Jasvinder Singh Bajaj took up the cudgels at a young age and in a short period Punjab
            Sind
            had operations spanning more than 4 states & 2 Union Territories via exclusive PUNJAB SIND FOODS dairy
            outlets<span id="dots1" style="display: none;">...</span>
            <span id="more1">Punjab Sind has had a humble beginning, from a small shop in Khar, Mumbai to presence
              across geographies, the
              flagship product being PANEER (Cottage Cheese) also amongst the pioneers to brand PANEER along with large
              diversified dairy products range & 70 plus exclusive Punjab Sind Foods dairy outlets is amongst the
              leading
              players in the dairy sector.

              A large portfolio of over 150 dairy products consisting of Paneer, Lassi, Buttermilk, Flavored Milk,
              Shrikhand,
              Ghee, Kulfi, Traditional sweets, Packaged Drinking Water, Frozen Vegetables, Greek Yogurt and FMCG
              products like
              Instant Gravy Mixes, WildFlower Raw Honey, Fruit Spreads, Sugar, Jaggery (powder format), Cheese etc.

              Punjab Sind Foods remains committed to “CUSTOMERS” and delighting customers with an ever diversifying
              range of top
              quality & delightful products marching ahead in a new globalised era and a new normal.</span>
          </p>

          <button (click)="myFunction1()" id="myBtn1" class="btn2">Read More</button>
        </div>
        <div class="write-up-our-story text-start d-block d-lg-none">
          <p>Punjab Sind was established more than 5 decades ago in the year 1969 by late Shri. Jagjit Singh Bajaj who
            inculcated the values of<span id="dots" style="display: none;">...</span>
            <span id="more"> “uncompromising quality” and “taste” at all times, his son the current Chairman &
              Managing Director, Mr. Jasvinder Singh Bajaj took up the cudgels at a young age and in a short period
              Punjab
              Sind
              had operations spanning more than 4 states & 2 Union Territories via exclusive PUNJAB SIND FOODS dairy
              outlets Punjab Sind has had a humble beginning, from a small shop in Khar, Mumbai to presence
              across geographies, the
              flagship product being PANEER (Cottage Cheese) also amongst the pioneers to brand PANEER along with large
              diversified dairy products range & 70 plus exclusive Punjab Sind Foods dairy outlets is amongst the
              leading
              players in the dairy sector.

              A large portfolio of over 150 dairy products consisting of Paneer, Lassi, Buttermilk, Flavored Milk,
              Shrikhand,
              Ghee, Kulfi, Traditional sweets, Packaged Drinking Water, Frozen Vegetables, Greek Yogurt and FMCG
              products like
              Instant Gravy Mixes, WildFlower Raw Honey, Fruit Spreads, Sugar, Jaggery (powder format), Cheese etc.

              Punjab Sind Foods remains committed to “CUSTOMERS” and delighting customers with an ever diversifying
              range of top
              quality & delightful products marching ahead in a new globalised era and a new normal.</span>
          </p>

          <button (click)="myFunction()" id="myBtn" class="btn2">Read More</button>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <img class="our-story-logo justify-content-center" src="/assets/PUNJAB SIND/logo/thumb-PS-Logo-Cropped.png"
          alt="">
      </div>
    </div>
  </div>
</section>

<!-- SHOP CARDS -->
<!-- 
<section id="shop">
  <div class="shop-container d-flex  justify-content-center">
    <div class="row">
      <div class="col  mt-3 mt-sm-2 ">
        <div class="shop-now cards  pb-sm-2" style="background-color: #c1f6a2;">
          <div class="row  g-3">
            <div class="col-12">
              <img class="card-banner  mt-2" src="assets/HYPER LOCAL DELIVERY Banner 2500 x 600.webp"
                alt="shop now img">
            </div>
            <div class="col-12">
              <p class="shop-write-up" style="width: 60%; margin: 0px auto; font-size: 1.5rem; text-align: center;">Get
                your Punjab Sind
                products delivered to you quickly on the same day.</p>
              <p class="shop-write-up mt-3" style="width: 60%; margin: 0px auto;font-size: 1rem; text-align: center;">
                Areas servicing:
                Mumbai, Pune, Kolhapur, and Hyderabad</p>
            </div>
            <div class="col-12 d-flex align-items-end  mb-2 mb-sm-4">
              <button class="btn2 d-flex align-items-end mx-auto">
                <a class="cor" href="http://shop.punjabsind.com/" target=_blank> Shop Now</a>
              </button> -->

<!-- <div>
                <h4 class="fontt" style="color: #FFFB69;">Connect with us:</h4>
                <h5 class="fontp " style="color: #ffffff;"><a
                    style="color: #ffffff; text-decoration: none; cursor: pointer;" href="tel:+91 96198 88880">96198
                    88880 </a>
                </h5>
              </div> -->

<!-- </div>
          </div>
        </div>
      </div> -->

<!-- <div class="col-12 col-md-6 col-lg-6 mt-2">
        <div class="pan-india cards" style="background-color: #f8a4dc;">
          <div class="row h-100 g-3">
            <div class="col-12">
              <img class="card-banner radius mt-1"
                src="/assets/PUNJAB SIND/3banner/PAN INDIA DELIVERY Banner 600 x 300-01.jpg" alt="pan india img">
            </div>
            <div class="col-12">
              <p class="shop-write-up" style="width: 60%; margin: 0px auto; text-align: center;">We are now delivering
                our
                products throughout India. </p>
            </div>
            <div class="col-12 d-flex align-items-end">
              <button class="btn2 d-flex align-items-end mx-auto">
                <a class="cor" href="https://punjabsind.store/">Shop Now</a> -->
<!-- <a class="cor" href="https://punjabsind.dotpe.in/" target=_blank> Shop Now</a> -->
<!-- </button>
            </div>
          </div>
        </div>
      </div> -->
<!-- <div class="col-12 col-md-4 mt-2">
        <div class="pan-india cards" style="background-color: #f0f6a2;">
          <div class="row h-100 g-3">
            <div class="col-12">
              <img class="card-banner radius mt-1"
                src="/assets/PUNJAB SIND/3banner/B2B DELIVERY Banner 600 x 300-01.jpg" alt="B2B img">
            </div>
            <div class="col-12">
              <p class="shop-write-up" style="width: 60%; margin: 0px auto; text-align: center;">Introducing B2B
                delivery
                for our dear business clients.</p>
              <p class="shop-write-up mt-3" style="width: 60%; margin: 0px auto; text-align: center;">Get access to and
                buy
                Punjab Sind’s institutional products in small or bulk quantities. </p>
            </div>
            <div class="col-12 d-flex align-items-end">
              <button class="btn2 d-flex align-items-end mx-auto">
                <a class="cor" href="https://punjabsind.store/">Shop Now</a>
                <a class="cor" href="https://punjabsind.dotpe.in/" target=_blank> Shop Now</a>
              </button>
            </div>
          </div>
        </div>
      </div> -->
<!-- </div>
  </div> -->
<!-- Pop-up -->
<!-- <div id="popup1" class="overlay mt-5">
    <div class="popup">
      <h4>This Service Will Be Available Soon!</h4>
      <a class="close" href="#">&times;</a> -->
<!-- <div class="content text-center">
       This Service Will Be Available Soon!
      </div> -->
<!-- </div>
  </div>
</section> -->

<!-- FOOTPRINTS -->

<section id="footprints" class="mt-2 mt-lg-4 mt-md-3">
  <img style="width: 90%; margin: 0px auto; text-align: center; border-radius: 25px !important;"
    src="/assets/PUNJAB SIND/BANNER 3/Punjab Sindh Banners Final-03.png" alt="">
</section>

<!-- OUR BELIEFS -->

<section id="our-beliefs">
  <div class="row">
    <div class="col-lg-5 col-sm-12">
      <div class="intro">
        <h1 class="title d-none d-md-none d-lg-block">Our Beliefs</h1>
        <h2 class="title d-block d-md-none d-lg-none">Our Beliefs</h2>
        <p class="our-beliefs-write-up">We strive to provide our customers with top-notch quality and natural products
          while maintaining their trust
          and
          faith in us. Delighting customers with a diverse range and high quality service has been our strong belief
          since
          1969.</p>
      </div>
    </div>
    <div class="col-lg-7 col-sm-12">
      <div class="icons">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <img class="icon" src="/assets/PUNJAB SIND/BANNER 4/QUALITY.png" alt="">
            <h4 class="tags">Quality</h4>
          </div>
          <div class="col-lg-4 col-sm-6">
            <img class="icon" src="/assets/PUNJAB SIND/BANNER 4/CONVENIENCE.png" alt="">
            <h4 class="tags" style="padding-top: 8%;">Convenience</h4>
          </div>
          <div class="col-lg-4 col-sm-6">
            <img class="icon" src="/assets/PUNJAB SIND/BANNER 4/TRUST.png" alt="">
            <h4 class="tags" style="padding-top: 14%;">Trust</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- PRODUCTS -->

<!-- TITLE FOR PRODUCTS -->

<section id="products">
  <h1 style="color: aliceblue;">Our Products</h1>
  <div class="row m-0">
    <div class="col-12 py-5 banner8 d-none d-md-none d-lg-block">
      <div class="container-fluid">
        <swiper [config]="configForTest1">
          <div class="swiper-wrapper align-items-center">
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 1-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 2-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 3-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 4-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 5-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 1-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </swiper>

      </div>
    </div>
    <div class="col-12 py-5 banner8 d-none d-md-block d-lg-none">
      <div class="container-fluid">
        <swiper [config]="configForTest1">
          <div class="swiper-wrapper align-items-center">
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 1-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 2-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 3-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 4-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row text-center align-items-center">
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 5-01-new.png" class="img-fluid  wid" alt="...">
                </div>
                <div class="col-6">
                  <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 1-01-new.png" class="img-fluid  wid" alt="...">
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </swiper>

      </div>
    </div>
    <div class="d-block d-md-none d-lg-none">
      <swiper [config]="configForTest">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="col-12 py-3 text-center align-items-center pt-5">
              <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 1-01-new.png" class="img-fluid wid" alt="...">
            </div>
          </div>

          <div class="swiper-slide">
            <div class="col-12  py-3 text-center align-items-center">
              <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 2-01-new.png" class="img-fluid wid" alt="...">
            </div>
          </div>

          <div class="swiper-slide">
            <div class="col-12  py-3 text-center align-items-center">
              <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 3-01-new.png" class="img-fluid wid" alt="..."
                style="padding-top: 25%;">
            </div>
          </div>

          <div class="swiper-slide">
            <div class="col-12  py-3 text-center align-items-center">
              <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 4-01-new.png" class="img-fluid wid" alt="...">
            </div>
          </div>

          <div class="swiper-slide">
            <div class="col-12  py-3 text-center align-items-center pt-5">
              <img src="/assets/PUNJAB SIND/BANNER 5/PRODUCT 5-01-new.png" class="img-fluid wid" alt="...">
            </div>
          </div>


        </div>
      </swiper>
    </div>
  </div>
</section>

<section id="paneer">
  <img class="paneer-banner d-none d-md-none d-lg-block"
    src="/assets/PUNJAB SIND/BANNER 6/Punjab Sindh Banners Final-06-new.png" alt="">
  <img class="paneer-banner d-block d-md-none d-lg-none"
    src="/assets/PUNJAB SIND/BANNER 6/Punjab Sindh Banners Final-06-new-phone.png" alt="">
</section>

<!-- OTHER PRODUCTS -->

<!-- <section id="other-products">
  <div class="container">
    <div class="row mt-2">
      <div class="col-12 col-md-3">
        <img class="d-block w-100 p8" src="/assets/GHEE.jpg" alt="Second slide">
      </div>
      <div class="col-12 col-md-3">
        <img class="d-block w-100 p9" src="/assets/Buzzee 2.jpg" alt="Second slide">
      </div>
      <div class="col-12 col-md-3">
        <img class="d-block w-100 p10" src="/assets/Cheese.jpg" alt="Second slide">
      </div>
      <div class="col-12 col-md-3">
        <img class="d-block w-100 p10" src="/assets/Mithai.jpg" alt="Second slide">
      </div>
    </div>
  </div>
</section> -->

<section id="smoothies">
  <img class="smoothie" src="/assets/SMOOTHIE_BANNER/BANNER-01.jpg" alt="">
</section>

<section id="new-products">
  <div class="row">
    <div class="col-lg-6 col-12">
      <img class="np" src="/assets/PS Papad POP (Mar22) FINAL.jpg" alt="">
    </div>
    <div class="col-lg-6 col-12 mt-3 mt-sm-0">
      <img src="/assets/InstaChef Pickles Range POP (Mar22) FINAL.jpg" class="np" alt="">
    </div>
  </div>
</section>

<section id="testimonials">
  <div class="title">
    <h1>Testimonials</h1>
  </div>
  <swiper [config]="configForTest">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="col-12 py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"I am a regular customer of Punjab Sind. Their
                products
                are so tasty and pure that
                no other sweets manufacturer has same quality and taste. All my friends and relatives want me to
                serve them sweets from them, they are super dupers."</p>
              <h6 class="card-title"><strong>-Anil Goel
                </strong></h6>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="col-12  py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"Punjab Sind ranks among the best places to go for
                the freshest quality dairy
                products, which explains their popularity. My personal favorite is the PANEER- it’s distinctive
                taste and incomparable quality has made me a Punjab Sind loyalist over the years."</p>
              <h6 class="card-title"><strong>-Yashna Bajaj</strong></h6><br>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="col-12  py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"Best dairy products specially lassi, available
                across
                all major cities."</p>
              <h6 class="card-title" style="padding-bottom:18px;"><strong>-Dhiraj Dubey <br /> Publisher - Food
                  and Beverage Matrix</strong></h6><br>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="col-12  py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"I’ve been buying Punjab Sind products for a few years now!
                Everything is always so fresh & well packaged so it’s easier to store. Have never eaten softer
                PANEER! They also have a wide range of exclusive flavors of Lassi, Flavored Milk & Kulfi’s and
                they’re all to die for!
                Would always recommend Punjab Sind" </p>
              <h6 class="card-title"><strong>-Vriti Daryanani
                </strong></h6><br>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="col-12  py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"Punjab Sind has a wide variety of products
                Their PANEER has been loved by everyone
                Plus their Lassi gives the authentic taste of <strong>Punjab Lassi</strong>
                And slowly they have been expanding into a wide range of products all with premium quality."
              </p>
              <h6 class="card-title"><strong>-Girivar Chadha</strong></h6><br>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="col-12 py-3 text-center">
          <div class="card">
            <div class="card-body bg">
              <p class="card-text">"I have been a regular customer of Punjab Sind since
                over 5 years for various
                dairy products, because of the consistency maintained in providing quality products. The range is
                spectacular and no one can return empty handed from their outlet."</p>
              <h6 class="card-title"><strong>-Utsav Rungta</strong></h6><br>
            </div>
          </div>
        </div>
      </div>

    </div>
  </swiper>
</section>

<!-- OUR BRANDS -->

<section id="our-brands" style="padding-bottom: 3%;">
  <div class="title">
    <h1>Our Brands</h1>
  </div>
  <div class="container d-none d-md-none d-lg-block">
    <div class="row align-items-center">
      <div class="col">
        <img src="assets/PUNJAB SIND/BANNER 11/FROZEN TREAT LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col">
        <img src="assets/PUNJAB SIND/BANNER 11/INSTA CHEF LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col">
        <img src="assets/PUNJAB SIND/BANNER 11/PUNJAB SIND LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col">
        <img src="assets/PUNJAB SIND/BANNER 11/BUZZEE LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col">
        <img src="assets/PUNJAB SIND/BANNER 11/BOMBAY SPICE LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
    </div>
  </div>
  <div class="container-fluid d-none d-md-block d-lg-none">
    <div class="row align-items-center">
      <div class="col-12 col-md">
        <img src="assets/PUNJAB SIND/BANNER 11/FROZEN TREAT LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col-12 col-md">
        <img src="assets/PUNJAB SIND/BANNER 11/INSTA CHEF LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col-12 col-md">
        <img src="assets/PUNJAB SIND/BANNER 11/PUNJAB SIND LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col-12 col-md">
        <img src="assets/PUNJAB SIND/BANNER 11/BUZZEE LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
      <div class="col-12 col-md">
        <img src="assets/PUNJAB SIND/BANNER 11/BOMBAY SPICE LOGO-01-new.png" class="img-fluid" alt="...">
      </div>
    </div>
  </div>
  <div class="d-block d-md-none d-lg-none">
    <swiper [config]="configForTest">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="col-12 py-3 text-center">
            <img src="assets/PUNJAB SIND/BANNER 11/FROZEN TREAT LOGO-01-new.png" class="img-fluid" alt="...">
          </div>
        </div>

        <div class="swiper-slide">
          <div class="col-12  py-3 text-center">
            <img src="assets/PUNJAB SIND/BANNER 11/INSTA CHEF LOGO-01-new.png" class="img-fluid" alt="...">
          </div>
        </div>

        <div class="swiper-slide">
          <div class="col-12  py-3 text-center">
            <img src="assets/PUNJAB SIND/BANNER 11/PUNJAB SIND LOGO-01-new.png" class="img-fluid" alt="...">
          </div>
        </div>

        <div class="swiper-slide">
          <div class="col-12  py-3 text-center">
            <img src="assets/PUNJAB SIND/BANNER 11/BUZZEE LOGO-01-new.png" class="img-fluid" alt="...">
          </div>
        </div>

        <div class="swiper-slide">
          <div class="col-12  py-3 text-center">
            <img src="assets/PUNJAB SIND/BANNER 11/BOMBAY SPICE LOGO-01-new.png" class="img-fluid" alt="...">
          </div>
        </div>
      </div>
    </swiper>
  </div>
</section>

<section id="contact-us">
  <div class="contact-container">
    <div class="row m-0">
      <div class="col-12">
        <div class="container-fluid padding1">
          <div class="row box cursor">
            <div class="col-md-4">
              <h4 class="fontt" style="color: #FFFB69;">Email:</h4>
              <h5 class="fontp" style="color: #ffffff;"><a
                  style="color: #ffffff;  text-decoration: none; cursor: pointer;"
                  href="mailto:info@punjabsind.com">info@punjabsind.com</a></h5><br />
              <h4 class="fontt" style="color: #FFFB69;">Connect with us:</h4>
              <h5 class="fontp " style="color: #ffffff;"><a
                  style="color: #ffffff; text-decoration: none; cursor: pointer;"
                  href="tel:+022-6775-5555">022-6775-5555 </a></h5> <br />
            </div>

            <div class="col-md-4">
              <h4 class="fontt " style="color: #FFFB69;">For Home Delivery:</h4>
              <h5 class="fontp">
                <a href="https://wa.me/+919619888880"  style="color: #ffffff;text-decoration: none; cursor: pointer;" target="_blank">
                 +91 9619 888 880
                </a>
              </h5><br>
              <h4 class="fontt " style="color: #FFFB69;">Follow us:</h4>
              <h5 class="fontp">
                <a href="https://www.instagram.com/punjabsind/"
                style="color: #ffffff;text-decoration: none; cursor: pointer;">
                  <img src="assets/PUNJAB SIND/instagram.png" height="20%" width="10%">&nbsp;punjabsind
                </a>
              </h5>
              <h5 class="fontp">
                <a href="https://www.facebook.com/punjabsind/"
                  style="color: #ffffff;text-decoration: none; cursor: pointer;">
                  <img src="assets/PUNJAB SIND/facebook.png" height="20%" width="10%">&nbsp;punjabsind
                </a>
              </h5><br />
            </div>

            <div class="col-md-4">
              <h4 class="pt fontt" style="color: #FFFB69;">Visit us at:</h4>
              <h5 class="fontp" style="color: #ffffff;">
                <span class="small">Punjab Sind Foods
                  (India) Pvt. Ltd</span> <br /> AG-2,
                Cama Industrial Estate,
                Walbhatt Road.
                Goregaon (E).
                Mumbai - 400 063.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
    <a href="https://wa.me/+919619888880" class="float" target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a>
  </div>
</section>